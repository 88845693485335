.swiper {
  width: 100%;
  height: 100%;
  margin-top: 75px;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.swiper-holder {
  height: 75vh;
}

.register-form-group {
  position: relative;
  padding-bottom: 1rem;
}

.register-form-group label {
  position: absolute;
  pointer-events: none;
  left: 0.5em;
  top: 1.2em;
  transition: 0.2s ease all;
  color: #ccc;
  font-size: 30px;
}

.register-form-group input[type=text] {
  background-color: transparent;
  padding: 0.75em 1.5em;
  line-height: 1.5em;
  border: 0;
  display: block;
  width: 100%;
  margin-top: 2em;
  border-bottom: 1px solid #4154f1;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, #4154f1 4%);
  background-position: -100em 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 0.5em;
  transition: 0.3s cubic-bezier(.64, .09, .08, 1) all;
  font-weight: bold;
}

.register-form-group input[type=text]:focus,
.register-form-group input[type=text]:valid {
  background-position: 0 0;

}

.register-form-group input[type=text]:focus ~ label,
.register-form-group input[type=text]:valid ~ label {
  color: #4154f1;
  font-size: 0.875em;
  top: 0.5em;
}

.register-form-group input[type=text]:focus {
  outline: none;
}