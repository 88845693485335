.custom-button {
  line-height: 0;
  padding: 25px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.btn-sm {
  padding: 20px 40px
}

.custom-primary-button {
  background: #4154f1;
}

.custom-button span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.custom-button i {
  font-size: 18px;
  transition: 0.3s;
}
.custom-button i.end {
  margin-left: 5px;
}
.custom-button i.start {
  margin-right: 5px;
}

.custom-button:hover i {
  transform: translateX(5px);
}

