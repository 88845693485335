.calendar {
  width: 100%;
  min-height: 100vh;
  background: url(../../../public/assets/img/hero-bg.png) top center no-repeat;
  background-size: cover;
}


.calendar-cards h2, .calendar-cards h5 {
  margin: 0;
}

.calendar-cards .day {
  margin: 0;
  font-size: 70px;
  margin-bottom: 0px;
  padding: 0;
  font-weight: 100;
}
.calendar-cards .month {
  margin-top: -15px;
  font-size: 20px;
}

.calendar-cards {
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-wrap: wrap;
}

.calendar-card {
  background: #fff;
  width: 24em;
  border-radius: 0.6em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.calendar-card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.calendar-card img {
  width: 100%;
  object-fit: cover;
}

.calendar-card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
}

.calendar-card p {
  color: #777;
}

.calendar-card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.calendar-card-body {
  padding: 1.2em;
}