body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-small {
  font-size: x-small;
}

.error-text {
  color: #bf1650;
}

.error-text::before {
  display: inline;
  content: "⚠ ";
}

textarea {
  resize: none;
}

input:focus,
textarea:focus, select:focus {
  border-color: #4154f1;
}

input,
textarea {
  border-radius: 0;
  box-shadow: unset;
  font-size: 14px;
}

input {
  padding: 10px 15px;
}

textarea {
  padding: 12px 15px;
}

button[type=submit] {
  background: #4154f1;
  border: 0;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

button[type=submit]:hover {
  background: #5969f3;
}


input:focus-visible,
input:focus,
textarea:focus-visible,
textarea:focus,
select:focus-visible,
select:focus {
  outline: unset;
  box-shadow: unset !important;
}