.modal-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.modal-form .form-group {
    margin-bottom: 25px;
}
.modal-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.form-check-label {
    margin-left: 10px;
}
.actions-btn {
    display: none;
}