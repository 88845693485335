
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  background: url(../../../../../public/assets/img/hero-bg.png) top center no-repeat;
  background-size: cover;
}
.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  color: #012970;
}
.hero h2 {
  color: #444444;
  margin: 15px 0 0 0;
  font-size: 26px;
}
.hero .hero-img {
  text-align: right;
}
.hero .hero-img .img-fluid {
  /* clip-path: circle(55% at 25% 65%); */
  position: relative;
  left: 90px;
}
@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
  }
  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }
  .hero .hero-img img {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .hero {
    text-align: center;
  }
  .hero h1 {
    font-size: 32px;
  }
  .hero h2 {
    font-size: 24px;
  }
  .hero .hero-img img {
    width: 100%;
  }
}